import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/videoCategory/page',
        method: 'GET',
        params
    });
}

// 保存
export function Save(data) {
    return request({
        url: '/admin/videoCategory/save',
        method: 'POST',
        data
    });
}

// 更新
export function Update(data) {
    return request({
        url: '/admin/videoCategory/update',
        method: 'POST',
        data
    });
}

// 详情
export function DetailMenu(params) {
    return request({
        url: '/admin/videoCategory/detail',
        method: 'GET',
        params
    });
}

// 删除
export function Delete(params) {
    return request({
        url: '/admin/videoCategory/delete',
        method: 'GET',
        params
    });
}


